import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { collection } from 'firebase/firestore';
// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { addDoc } from 'firebase/firestore';
import { serverTimestamp } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCK8o2adn6otbcaJcmp3u8WbatfweWqrIM",
    authDomain: "first-survey-411e0.firebaseapp.com",
    projectId: "first-survey-411e0",
    storageBucket: "first-survey-411e0.appspot.com",
    messagingSenderId: "429090951931",
    appId: "1:429090951931:web:1b4f53b4c6fd52fd71d65e",
    measurementId: "G-RCSDMWR884"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);

// firebase collections
const farmer = collection(db, 'farmer_answers');
const commerce = collection(db, 'commerce_answers');

export {
    db,
    addDoc,
    farmer,
    commerce,
    analytics,
    serverTimestamp
};