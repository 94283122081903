<template>
    <survey :survey="survey"/>
</template>
<script>
import Vue from "vue";
import {Model} from "survey-vue";
import "survey-vue/defaultV2.min.css";
import "./index.css";
import {addDoc, farmer, commerce, serverTimestamp} from '@/firebaseConfig';


export default Vue.component("survey-component", {
    name: "survey-component",
    props: {
        type: {
            type: String,
            required: true,
            validator: function (value) {
                return ["farmer", "commerce"].includes(value);
            }
        },
        json: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            survey: null,
        };
    },
    created() {
        this.updateSurvey()
    },
    watch: {
        $route() {
            this.updateSurvey();
        },
    },
    methods: {
        updateSurvey() {
            const survey = new Model(this.json);
            survey.onComplete.add(this.surveyComplete);
            survey.locale = 'gr';
            survey.showPrevButton = true;
            survey.showQuestionNumbers = true;
            this.survey = survey;
        },

        async surveyComplete(sender) {
            try {
                const answersCollection = this.type === "farmer" ? farmer : commerce;
                await addDoc(answersCollection, {
                    ...sender.data,
                    createdOn: serverTimestamp(),
                    updatedOn: serverTimestamp()
                });
            } catch (error) {
                console.error("Error adding document: ", error);
            }
        }
    },
});
</script>

