export const commerce = {
    "title": "Ερωτηματολόγιο e-commerce",
    "elements": [
        {
            "type": "text",
            "name": "company_name",
            "title": "Επωνυμία",
            "isRequired": false,
        },
        {
            "type": "checkbox",
            "name": "profession",
            "title": "Ποια είναι η επαγγελματική σας δραστηριότητα στον αγροτικό τομέα;",
            "isRequired": false,
            "showOtherItem": true,
            "colCount": 1,
            "choices": ["Έμπορος γεωργικού εξοπλισμόυ", "Έμπορος φυτοφαρμάκων/λιπασμάτων", "Έμπορος σπόρων", "Έμπορος φρούτων και λαχανικών"],
            // "separateSpecialChoices": true,
        },
        {
            "type": "checkbox",
            "name": "business_type",
            "title": "Τι επιχειρηματικό μοντέλο ακολουθείτε;",
            "isRequired": false,
            "colCount": 1,
            "choices": ["b2b", "b2c"],
            "showSelectAllItem": true,
        },
        {
            "type": "text",
            "name": "associate_number",
            "title": "Συνεργάζεστε απευθείας με παραγωγούς; Αν ναι, με τι αριθμό παραγωγών;",
            "isRequired": false,
        },
        {
            "type": "checkbox",
            "name": "medium_preference",
            "title": "Ποιο μέσο χρησιμοποιείτε περισσότερο για να πλοηγηθείτε στο διαδίκτυο;",
            "isRequired": false,
            "colCount": 1,
            "choices": ["Κινητό τηλέφωνο", "Ηλεκτρονικό υπολογιστή (laptop/PC)", "Tablet"],
            "showSelectAllItem": true,
            // "separateSpecialChoices": true,
        },
        {
            "type": "text",
            "name": "platform",
            "title": "Χρησιμοποιείτε κάποια πλατφόρμα για να προωθήσετε τα προϊόντα σας;",
            "isRequired": false,
        },
        {
            "type": "checkbox",
            "name": "platform_difficulty",
            "title": "Αν ναι, υπάρχει κάτι που σας δυσκολεύει στο χειρισμό της;",
            "description": "Μπορείτε να επιλέξτε περισσότερα από ένα",
            "choices": ["Δυσκολία στην προώθηση προϊόντων", "Δυσκολία στις οικονομικές συναλλαγές", "Δυσκολία στην εύρεση πελατών"],
            "isRequired": false,
            "colCount": 1,
            "showOtherItem": true,
        },
        {
            "type": "checkbox",
            "name": "social_media",
            "title": "Χρησιμοποιείτε κάποιο κοινωνικό δίκτυο για να βρείτε πληροφορίες σχετικές με την εργασία σας;",
            "description": "Μπορείτε να επιλέξτε περισσότερα από ένα",
            "choices": ["Facebook", "Twitter", "Linkedin", "Instagram"],
            "isRequired": false,
            "colCount": 1,
            "showNoneItem": true,
            "showOtherItem": true,
            "showSelectAllItem": true,
            "separateSpecialChoices": true
        },
        {
            "type": "checkbox",
            "name": "associate_track",
            "title": "Πώς εντοπίζετε τους συνεργάτες σας;",
            "description": "Μπορείτε να επιλέξτε περισσότερα από ένα",
            "choices": ["Internet", "Εκθέσεις", "Μέσω πωλητών", "Social media", "Ιστοσελίδα"],
            "isRequired": false,
            "colCount": 1,
            "showOtherItem": true,
        },
        {
            "type": "rating",
            "name": "knowledge_exchange",
            "title": "Πόσο θα σας ενδιέφερε να ανταλλάξετε γνώσεις και απόψεις για το αντικείμενο της επιχείρησής σας με άλλα άτομα του ίδιου τομέα; (1 καθόλου, 5 πολύ)",
            "rateMax": 5,
            "maxRateDescription": "Πάρα πολύ",
            "minRateDescription": "Καθόλου",
            "isRequired": false,
        },
        {
            "type": "text",
            "name": "contact_info",
            "title": "Αν επιθυμείτε, συμπληρώστε έναν επιθυμητό τρόπο επικοινωνίας",
            "placeholder": "Στοιχεία επικοινωνίας",
            "isRequired": false,
        }
    ],
    "showPrevButton": false,
    "completeText": "Επόμενο",
    "questionsOnPageMode": "questionPerPage",
    "showQuestionNumbers": false,
    "widthMode": "static",
    // "width": "1000px"
};

