<template>
    <div id="app">
        <header>
            <nav>
                <ul>
                    <li>
                        <router-link to="/farmer">Αγρότες</router-link>
                    </li>
                    <li>
                        <router-link to="/commerce">Έμποροι</router-link>
                    </li>
                </ul>
            </nav>
        </header>
        <main>
            <router-view></router-view>
        </main>
    </div>
</template>

<script>
export default {
    name: 'App',
};
</script>


<style>
header {
    padding: 1rem;
}

nav ul {
    display: flex;
    list-style: none;
    justify-content: space-around;
    padding: 0;
}

nav li {
    margin-right: 1rem;
}

nav li:last-child {
    margin-right: 0;
}

nav a {
    color: #19b394;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.2s;
}

nav a:hover {
    color: #117d67;
}

/* Styling for the active link */
.router-link-active {
    text-decoration: underline;
}

/* Styling for the main content */
main {
    margin-top: 1rem;
}
</style>
