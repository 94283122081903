import Vue from 'vue';
import Router from 'vue-router';
import SurveyComponent from './components/SurveyComponent.vue';
import { farmer } from './assets/questionnaires/farmer';
import { commerce } from './assets/questionnaires/commerce';

Vue.use(Router);

const routes = [
    {
        path: '/farmer',
        component: SurveyComponent,
        props: { json: farmer, type: 'farmer' }
    },
    {
        path: '/commerce',
        component: SurveyComponent,
        props: { json: commerce, type: 'commerce' }
    }
];

export default new Router({
    mode: 'history',
    routes
});
