export const farmer = {
    "title": "Ερωτηματολόγιο αγρότες",
    "elements": [
        {
            "type": "radiogroup",
            "name": "profession",
            "title": "Ποια είναι η επαγγελματική σας δραστηριότητα στον αγροτικό τομέα;",
            "isRequired": false,
            "showOtherItem": true,
            "colCount": 2,
            "choices": ["Παραγωγός", "Γεωπόνος", "Μεσίτης γης", "Πωλητής Γεωργικού Εξοπλισμού", "Πωλητής Φυτοφαρμάκων", "Πωλητής Λιπασμάτων", "Πωλητής Σπόρων", "Εργάτης γης"],
        },
        {
            "type": "text",
            "name": "field_size",
            "title": "Αν είστε παραγωγός πόσα στρέμματα καλλιεργείτε;",
            "isRequired": false,
        },
        {
            "type": "checkbox",
            "name": "cultivation_type",
            "title": "Αν είστε παραγωγός, τι καλλιεργείτε;",
            "description": "Μπορείτε να επιλέξτε περισσότερα από ένα",
            "choices": ["Βιολογικά", "Εναλλακτικές καλλιέργειες (π.χ. βιολογικές)", "Κηπευτικά/Λαχανοκομικά", "Καρποφόρα δένδρα", "Σιτηρά", "Κτηνοτροφικά φυτά", "Βότανα", "Αμπέλια", "Ειδικές καλλιέργειες (π.χ. βαμβάκι)"],
            "isRequired": false,
            "colCount": 2,
            "showOtherItem": true,
        },
        {
            "type": "radiogroup",
            "name": "profession",
            "title": "Ποια είναι η ηλικία σας;",
            "isRequired": false,
            "colCount": 1,
            "choices": ["18-30", "31-45", "46-60", "61-80", "άνω των 80"],
        },
        {
            "type": "checkbox",
            "name": "work_area",
            "title": "Σε ποια γεωγραφική περιοχή δραστηριοποιείστε επαγγελματικά;",
            "description": "Μπορείτε να επιλέξτε περισσότερα από ένα",
            "choices": ["Θράκη", "Ανατολική Μακδεονία", "Κεντρική Μακεδονία", "Δυτική Μακεδονία", "Ήπειρο", "Θεσσαλία", "Στερεά Ελλάδα", "Αττική", "Πελοπόννησο", "Nησιά Ιονίου", "Νησιά Αιγαίου", "Κρήτη", "Δωδεκάνησα"],
            "isRequired": false,
            "colCount": 1,
        },
        {
            "type": "checkbox",
            "name": "medium_preference",
            "title": "Ποιο μέσο χρησιμοποιείτε περισσότερο για να πλοηγηθείτε στο διαδίκτυο;",
            "isRequired": false,
            "colCount": 1,
            "choices": ["Κινητό τηλέφωνο", "Ηλεκτρονικό υπολογιστή (laptop/PC)", "Tablet"],
            "showSelectAllItem": true,
        },
        {
            "type": "checkbox",
            "name": "info_search",
            "title": "Τι πληροφορίες αναζητάτε σχετικά με την εργασία σας στο διαδίκτυο;",
            "description": "Μπορείτε να επιλέξτε περισσότερα από ένα",
            "choices": ["Νέα", "Χρηματοδοτικά προγράμματα", "Οδηγίες για φυτοφάρμακα/ραντίσματα", "Εκθέσεις/Εκδηλώσεις", "Ευρωπαϊκές οδηγίες", "Πιστοποιήσεις", "Καιρός"],
            "isRequired": false,
            "colCount": 1,
            "showOtherItem": true,
        },
        // {
        //     "type": "rating",
        //     "name": "knowledge_exchange",
        //     "title": "Πόσο θα σας ενδιέφερε να ανταλλάξετε γνώσεις και απόψεις για το αντικείμενο της παραγωγής ή γενικότερα της εργασίας σας με άλλα άτομα του ίδιου τομέα;",
        //     "rateMax": 5,
        //     "maxRateDescription": "Πάρα πολύ",
            // "minRateDescription": "Καθόλου",
            // "isRequired": false,
        // },
        {
            "type": "checkbox",
            "name": "information_source",
            "title": "Ποιες πηγές χρησιμοποιείτε για αναζήτηση πληροφοριών σχετικά με την εργασία σας;",
            "description": "Μπορείτε να επιλέξτε περισσότερα από ένα",
            "choices": ["Κοινωνικά δίκτυα", "Διαδίκτυο", "Γεωπόνος", "Συνεταιρισμός", "Τύπος", "Τηλεόραση", "Συνάδελφοι", "Συμβουλευτική εταιρεία"],
            "isRequired": false,
            "colCount": 1,
            "showNoneItem": true,
        },
        {
            "type": "checkbox",
            "name": "social",
            "title": "Χρησιμοποιείτε κάποιο κοινωνικό δίκτυο για να βρείτε πληροφορίες σχετικές με την εργασία σας;",
            "description": "Μπορείτε να επιλέξτε περισσότερα από ένα",
            "choices": ["Facebook", "Twitter", "Linkedin", "Instagram"],
            "isRequired": false,
            "colCount": 1,
            "showOtherItem": true,
        },
        {
            "type": "checkbox",
            "name": "social_difficulties",
            "title": "Αν χρησιμοποιείτε κάποιο κοινωνικό δίκτυο, υπάρχει κάτι που σας δυσκολεύει στον χειρισμό του ή στον εντοπισμό της πληροφορίας που ψάχνετε;",
            "description": "Μπορείτε να επιλέξτε περισσότερα από ένα",
            "choices": ["Δυσκολία στη χρήση του chat", "Δυσκολία στο σχολιασμό", "Δυσκολία στην εύρεση της πληροφορίας που αναζητάτε",],
            "isRequired": false,
            "colCount": 1,
            "showOtherItem": true,
        },
        {
            "type": "checkbox",
            "name": "social_features",
            "title": "Ποια χαρακτηριστικά πιστεύετε ότι πρέπει να έχει ένα κοινωνικό δίκτυο για τον αγροτικό τομέα;",
            "description": "Μπορείτε να επιλέξτε περισσότερα από ένα",
            "choices": ["Δυνατότητα σύνδεσης με άλλους επαγγελματίες του κλάδου", "Πρόσβαση σε νέα και πληροφορίες του κλάδου", "Αγοραπωλησία αγροτικών προϊόντων", "Υπηρεσίες γεωπόνου (π.χ. δοκιμές εδάφους, συμβουλές καλλιέργειας)", "Ενοικίαση/Αγορά γης", "Προγράμματα χρηματοδότησης", "Αγροτικό ημερολόγιο με ειδοποιήσεις", "Εξειδικευμένες πληροφορίες σχετικά με τις ατομικές σας καλλιέργειες (π.χ. υγρασία)"],
            "isRequired": false,
            "colCount": 1,
            "showOtherItem": true,
        },
        {
            "type": "radiogroup",
            "name": "spora_like",
            "title": "Θα βλέπατε θετικά να δημιουργούσατε ένα δωρεάν λογαριασμό στο κοινωνικό δίκτυο αγροτών Spora;",
            "isRequired": false,
            "colCount": 1,
            "choices": ["Ναι", "Όχι"],
        },
        {
            "type": "text",
            "name": "contact_info",
            "title": "Αν επιθυμείτε, συμπληρώστε έναν επιθυμητό τρόπο επικοινωνίας",
            "placeholder": "Στοιχεία επικοινωνίας",
            "isRequired": false,
        }
    ],
    "showPrevButton": false,
    "completeText": {
        "fr": "Envoyer",
        "gr": "Επόμενο",
    },
    "questionsOnPageMode": "questionPerPage",
    "showQuestionNumbers": false,
    "widthMode": "static",
    "width": "1000px"
};

